import { useEffect } from "react";
import Head from "next/head";
import { useFetchUser } from "../../lib/user";
import Header from "./header.js";
import { initGA } from "../Analytics/google-analytics";
import { OrganizationSchema, HomeFaqSchema } from "../schema";
import { useRouter } from "next/router";
import { imageBasePath } from "lib/utils/imageUtils";

export default (props) => {
  const {
    title,
    children,
    isPrivate,
    showBackground,
    middleElement,
    showNavLinks,
    forceMobileBackground,
    invertLogo,
    showAvatar = false,
    bottomCta,
    bankBottomCta,
    description,
    mainBannerText,
    showEllisBanner,
  } = props;
  const { user, loading } = useFetchUser();
  const userIsLoggedIn = !loading && user;

  const router = useRouter();

  useEffect(() => {
    initGA();
  }, []);

  return (
    <div className="font-sans w-screen md:min-h-screen h-full antialiased mx-auto relative">
      <Head>
        <title>{`${title} | Leap Finance`}</title>
        {description && (
          <>
            <meta name="description" content={description} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@LeapFinanceIn" />
            <meta
              content={`${title} | Leap Finance`}
              property="twitter:title"
            />
            <meta
              property="og:url"
              content={`https://leapfinance.com${router.pathname}`}
            />
            <meta property="og:title" content={`${title} | Leap Finance`} />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content="http://cdn.mcauto-images-production.sendgrid.net/34a5d9ee598a3b60/90aa2b54-ae81-474f-88a1-c6daa7dbc5b5/192x192.png"
            />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="101199618003786" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
          </>
        )}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/apple-touch-icon.png"
        />
        <link rel="manifest" href="/static/site.webmanifest"></link>
        <link
          href="https://d3qj1pefcqovqy.cloudfront.net/61a5bb0516b045285e37ec59_logo_leap_1_2_8731b923b7.png"
          rel="shortcut icon"
          type="image/x-icon"
        />
        {(router.pathname === "/" || router.pathname === "/about") && (
          <OrganizationSchema />
        )}
        {router.pathname === "/" && <HomeFaqSchema />}
      </Head>
      <Header
        showBackground={showBackground}
        middleElement={middleElement}
        showNavLinks={showNavLinks}
        forceMobileBackground={forceMobileBackground}
        invertLogo={invertLogo}
        showAvatar={showAvatar}
        bottomCta={bottomCta}
        bankBottomCta={bankBottomCta}
        user={user}
        loading={loading}
        mainBannerText={mainBannerText}
        showEllisBanner={showEllisBanner}
      />

      <main>
        {loading && (
          <div className="w-full h-screen fixed inset-0 flex justify-center items-center">
            <img
              src={`${imageBasePath}/assets/images/loader-small.gif`}
              alt="Loading..."
            />
          </div>
        )}
        {!loading && (userIsLoggedIn || !isPrivate) && children(user)}
        {!loading && !userIsLoggedIn && isPrivate && <LoginNotice />}
      </main>
    </div>
  );
};

const LoginNotice = () => (
  <div className="bg-gray-100 min-h-screen pb-10">
    <div className="empty-container">
      <div>
        <div className="offer-outer-container pt-24">
          <div className="box-white px-4 sm:px-12 pb-1 mb-4 mt-auto pt-auto">
            <div className="ml-auto mr-auto max-w-tiny pt-12 mb-8">
              <img src={`${imageBasePath}/assets/images/offer/lock.png`} />
            </div>
            <div className="title-holder w-full text-center text-xl md:text-2xl sm:text-xl">
              <span>Please login to continue</span>
            </div>
            <div className="subtitle-holder w-full text-center text-base sm:px-4 mb-8">
              <span>
                Click{" "}
                <a className="underline text-blue-500" href="/login">
                  here
                </a>{" "}
                to login
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
